<template>
  <component :is="'b-card'">
    <b-tabs pills>

      <b-tab :title="$t('administration.products.tabs.general.label')">
        <b-overlay
          :show="Object.keys(catalog.title).length === 0"
          rounded="sm"
        >
          <b-form-group
            :label="$t('form.indexing_status.label')"
            label-for="indexing_status"
            :state="errors && errors.indexing_status ? false : null"
          >
            <v-select
              id="indexing_status"
              v-model="catalog.indexing_status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="indexing_status"
            />
            <b-form-invalid-feedback v-if="errors && errors.indexing_status">
              {{ errors.indexing_status[0] }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-tabs>
            <b-tab
              v-for="language in languages"
              :key="language.code"
            >
              <template #title>
                <b-img
                  :src="require('@/assets/images/flags/' + language.code + '.svg')"
                  height="16"
                  width="16"
                  class="mr-1"
                />
                <span class="d-none d-sm-inline">{{ language.title }}</span>
              </template>
              <b-row>
                <b-col
                  cols="24"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.title.label')"
                    :label-for="'title.' + language.code"
                  >
                    <b-form-input
                      :id="'title.' + language.code"
                      v-model="catalog.title[language.code]"
                      :state="errors && errors['title.' + language.code] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                      {{ errors['title.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="24"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.meta_h1.label')"
                    :label-for="'meta_h1.' + language.code"
                  >
                    <b-form-input
                      :id="'meta_h1.' + language.code"
                      v-model="catalog.meta_h1[language.code]"
                      :state="errors && errors['meta_h1.' + language.code] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['meta_h1.' + language.code]">
                      {{ errors['meta_h1.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="24"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.meta_title.label')"
                    :label-for="'meta_title.' + language.code"
                  >
                    <b-form-input
                      :id="'meta_title.' + language.code"
                      v-model="catalog.meta_title[language.code]"
                      :state="errors && errors['meta_title.' + language.code] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['meta_title.' + language.code]">
                      {{ errors['meta_title.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="24"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.meta_description.label')"
                    :label-for="'meta_description.' + language.code"
                  >
                    <b-form-textarea
                      :id="'meta_description.' + language.code"
                      v-model="catalog.meta_description[language.code]"
                      :state="errors && errors['meta_description.' + language.code] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['meta_description.' + language.code]">
                      {{ errors['meta_description.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="24"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.meta_keywords.label')"
                    :label-for="'meta_keywords.' + language.code"
                  >
                    <b-form-textarea
                      :id="'meta_keywords.' + language.code"
                      v-model="catalog.meta_keywords[language.code]"
                      :state="errors && errors['meta_keywords.' + language.code] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['meta_keywords.' + language.code]">
                      {{ errors['meta_keywords.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="24"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.canonical.label')"
                    :label-for="'canonical.' + language.code"
                  >
                    <b-form-input
                      :id="'canonical.' + language.code"
                      v-model="catalog.canonical[language.code]"
                      :state="errors && errors['canonical.' + language.code] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['canonical.' + language.code]">
                      {{ errors['canonical.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  :class="`hidden`"
                  cols="24"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.description.label')"
                    :label-for="'description.' + language.code"
                    :state="errors && errors['description.' + language.code] ? false : null"
                  >
                    <quill-editor
                      :id="'description.' + language.code"
                      v-model="catalog.description[language.code]"
                      :state="errors && errors['description.' + language.code] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['description.' + language.code]">
                      {{ errors['description.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>

          <b-row
            v-if="$ability.can('update', 'settings')"
            class="mt-2"
          >
            <b-col>
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="submitCatalog"
              >
                {{ $t('general.save') }}
              </b-button>
            </b-col>
          </b-row>
        </b-overlay>
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('administration.seo_block.title') }}</span>
        </template>
        <b-overlay
          :show="data === null"
          rounded="sm"
        >
          <b-form
            v-if="data"
            class="mt-1"
          >
            <b-row>
              <b-col
                cols="12"
                md="8"
              >
                <b-tabs>
                  <b-tab
                    v-for="language in languages"
                    :key="language.code"
                  >
                    <template #title>
                      <b-img
                        :src="require('@/assets/images/flags/' + language.code + '.svg')"
                        height="16"
                        width="16"
                        class="mr-1"
                      />
                      <span class="d-none d-sm-inline">{{ language.title }}</span>
                    </template>
                    <b-row>
                      <b-col
                        cols="24"
                        md="12"
                      >
                        <b-form-group
                          :label="$t('form.title.label')"
                          :label-for="'title.' + language.code"
                        >
                          <b-form-input
                            :id="'title.' + language.code"
                            v-model="seo.title[language.code]"
                            :state="errors && errors['title.' + language.code] ? false : null"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                            {{ errors['title.' + language.code][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="24"
                        md="12"
                      >
                        <b-form-group
                          :label="$t('form.description.label')"
                          :label-for="'description.' + language.code"
                          :state="errors && errors['description.' + language.code] ? false : null"
                        >
                          <quill-editor
                            :id="'description.' + language.code"
                            v-model="seo.description[language.code]"
                            :options="editorOptions"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['description.' + language.code]">
                            {{ errors['description.' + language.code][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-tab>
                </b-tabs>
              </b-col>

              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  :label="$t('form.avatar.label')"
                  :state="errors && errors.avatar ? false : null"
                >
                  <!-- media -->
                  <b-media no-body>
                    <b-media-aside>
                      <b-link>
                        <b-img
                          ref="previewEl"
                          rounded
                          :src="$options.filters.mediaUrl(seo, 'avatar', '110x110')"
                          height="80"
                        />
                      </b-link>
                      <!--/ avatar -->
                    </b-media-aside>

                    <b-media-body class="mt-75 ml-75">
                      <InitFileManager
                        field="avatar"
                        type="catalog_seo_avatar"
                        :multiple="false"
                        @fm-selected="selectedAvatar"
                      />

                      <!-- reset -->
                      <b-button
                        v-if="seo.avatar"
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        size="sm"
                        class="mb-75 mr-75"
                        @click="seo.avatar = null"
                      >
                        {{ $t('general.reset') }}
                      </b-button>
                      <!--/ reset -->

                    </b-media-body>
                  </b-media>
                  <!--/ media -->
                </b-form-group>

                <b-form-invalid-feedback v-if="errors && errors.avatar">
                  {{ errors.avatar[0] }}
                </b-form-invalid-feedback>
              </b-col>
            </b-row>

            <h4>{{ $t('administration.extensions.tabs.faq.label') }}</h4>
            <hr>

            <b-tabs>
              <b-tab
                v-for="language in languages"
                :key="language.code"
              >
                <template #title>
                  <b-img
                    :src="require('@/assets/images/flags/' + language.code + '.svg')"
                    height="16"
                    width="16"
                    class="mr-1"
                  />
                  <span class="d-none d-sm-inline">{{ language.title }}</span>
                </template>

                <div class="repeater-form">
                  <b-row
                    v-for="(faqItem, index) in seo.faq"
                    :id="'faq' + index + language.code"
                    :key="index"
                    ref="row"
                  >
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group
                        :label="$t('form.question.label')"
                        :label-for="'faq.' + index + '.question.' + language.code"
                      >
                        <b-form-input
                          :id="'faq.' + index + '.question.' + language.code"
                          v-model="faqItem.question[language.code]"
                          :state="errors && errors['faq.' + index + '.question.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['faq.' + index + '.question.' + language.code]">
                          {{ errors['faq.' + index + '.question.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.answer.label')"
                        :label-for="'answer.' + language.code"
                        :state="errors && errors['answer.' + language.code] ? false : null"
                      >
                        <quill-editor
                          :id="'answer.' + language.code"
                          v-model="faqItem.answer[language.code]"
                          :options="editorOptions"
                          :state="errors && errors['answer.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['answer.' + language.code]">
                          {{ errors['answer.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>

                    <!-- Remove Button -->
                    <b-col
                      lg="2"
                      md="3"
                      class="mb-50"
                    >
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        class="mt-0 mt-md-2"
                        @click="removeFaq(index)"
                      >
                        <feather-icon
                          icon="XIcon"
                          class="mr-25"
                        />
                        <span>{{ $t('general.delete') }}</span>
                      </b-button>
                    </b-col>
                    <b-col cols="12">
                      <hr>
                    </b-col>
                  </b-row>
                </div>
              </b-tab>
            </b-tabs>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="addFaq"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>{{ $t('general.add') }}</span>
            </b-button>

            <b-row
              v-if="errors && errors.faq"
            >
              <b-col cols="12">
                <b-form-invalid-feedback :state="false">
                  {{ errors.faq[0] }}
                </b-form-invalid-feedback>
              </b-col>
            </b-row>

            <b-row
              v-if="$ability.can('update', 'settings')"
              class="mt-2"
            >
              <b-col>
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="submitSeo"
                >
                  {{ $t('general.save') }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-overlay>
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('administration.navigation.extensions') }}</span>
        </template>
        <b-overlay
          :show="data === null"
          rounded="sm"
        >
          <b-form
            v-if="data"
            class="mt-1"
          >
            <b-row>
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  :label="$t('form.top_extension_id.label')"
                  label-for="top_extension_id"
                  :state="errors && errors.top_extension_id ? false : null"
                >
                  <v-select
                    id="top_extension_id"
                    v-model="catalog.top_extension_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="extensionOptions"
                    :reduce="val => val.value"
                    input-id="top_extension_id"
                    :searchable="true"
                    @search="searchExtension"
                  />
                  <b-form-invalid-feedback v-if="errors && errors.top_extension_id">
                    {{ errors.top_extension_id[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  :label="$t('form.bottom_extension_id.label')"
                  label-for="bottom_extension_id"
                  :state="errors && errors.bottom_extension_id ? false : null"
                >
                  <v-select
                    id="bottom_extension_id"
                    v-model="catalog.bottom_extension_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="extensionOptions"
                    :reduce="val => val.value"
                    input-id="bottom_extension_id"
                    :searchable="true"
                    @search="searchExtension"
                  />
                  <b-form-invalid-feedback v-if="errors && errors.bottom_extension_id">
                    {{ errors.bottom_extension_id[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row
              v-if="$ability.can('update', 'settings')"
              class="mt-2"
            >
              <b-col>
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="submitCatalog"
                >
                  {{ $t('general.save') }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-overlay>
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('administration.catalog_filter.title') }}</span>
        </template>
        <b-overlay
          :show="data === null"
          rounded="sm"
        >
          <b-form
            v-if="data"
            class="mt-1"
          >
            <div class="repeater-form">
              <b-row
                v-for="(attribute, index) in data"
                :id="'attribute' + index"
                :key="index"
                ref="row"
              >
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.attribute_id.label')"
                    :label-for="'attributes.' + index + '.attribute_id'"
                    :state="errors && errors[index + '.attribute_id'] ? false : null"
                  >
                    <v-select
                      :id="'attributes.' + index + '.attribute_id'"
                      v-model="attribute.attribute_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="attributesOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      :input-id="'attributes.' + index + '.attribute_id'"
                      @input="loadAttributeValue($event, index)"
                    />
                    <b-form-invalid-feedback v-if="errors && errors[index + '.attribute_id']">
                      {{ errors[index + '.attribute_id'][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.attribute_value_id.label')"
                    :label-for="'attributes.' + index + '.attribute_value_id'"
                    :state="errors && errors[index + '.attribute_value_id'] ? false : null"
                  >
                    <v-select
                      :id="'attributes.' + index + '.attribute_value_id'"
                      v-model="attribute.attribute_value_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="attributeValuesOptions[`attribute_${attribute.attribute_id}`]"
                      :disabled="!attributeValuesOptions[`attribute_${attribute.attribute_id}`]"
                      :reduce="val => val.value"
                      :clearable="true"
                      :input-id="'attributes.' + index + '.attribute_value_id'"
                      :taggable="true"
                      :create-option="val => ({ value: `new_${val}`, label: val })"
                    />
                    <b-form-invalid-feedback v-if="errors && errors[index + '.attribute_value_id']">
                      {{ errors[index + '.attribute_value_id'][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.position.label')"
                    :label-for="'attributes.' + index + '.position'"
                  >
                    <b-form-input
                      :id="'attributes.' + index + '.position'"
                      v-model="attribute.position"
                      :state="errors && errors[index + '.position'] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors[index + '.position']">
                      {{ errors[index + '.' + '.position'][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.can_indexing.label')"
                    :label-for="'attributes.' + index + '.can_indexing'"
                  >
                    <b-form-checkbox
                      :id="'attributes.' + index + '.can_indexing'"
                      v-model="attribute.can_indexing"
                      :state="errors && errors[index + '.can_indexing'] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors[index + '.can_indexing']">
                      {{ errors[index + '.' + '.can_indexing'][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.hide_empty.label')"
                    :label-for="'attributes.' + index + '.hide_empty'"
                  >
                    <b-form-checkbox
                      :id="'attributes.' + index + '.hide_empty'"
                      v-model="attribute.hide_empty"
                      :state="errors && errors['attributes.' + index + '.hide_empty'] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['attributes.' + index + '.hide_empty']">
                      {{ errors['attributes.' + index + '.' + '.hide_empty'][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <!-- Remove Button -->
                <b-col
                  lg="2"
                  md="3"
                  class="mb-50"
                >
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    @click="removeAttribute(index)"
                  >
                    <feather-icon
                      icon="XIcon"
                      class="mr-25"
                    />
                    <span>{{ $t('general.delete') }}</span>
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </div>

            <b-row>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addAttribute"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.attributes"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.attributes[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
            </b-row>
            <b-row
              v-if="$ability.can('update', 'settings')"
              class="mt-2"
            >
              <b-col>
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="submit"
                >
                  {{ $t('general.save') }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-overlay>
      </b-tab>

      <b-tab :title="$t('administration.categories.tabs.tags.label')">
        <b-tabs>
          <b-tab
            v-for="language in languages"
            :key="language.code"
          >
            <template #title>
              <b-img
                :src="require('@/assets/images/flags/' + language.code + '.svg')"
                height="16"
                width="16"
                class="mr-1"
              />
              <span class="d-none d-sm-inline">{{ language.title }}</span>
            </template>

            <div class="repeater-form">
              <b-row
                v-for="(tag, index) in catalog.tags"
                :id="'tag' + index + language.code"
                :key="index"
                ref="row"
              >
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.title.label')"
                    :label-for="'tags.' + index + '.title.' + language.code"
                  >
                    <b-form-input
                      :id="'tags.' + index + '.title.' + language.code"
                      v-model="tag.title[language.code]"
                      :state="errors && errors['tags.' + index + '.title.' + language.code] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['tags.' + index + '.title.' + language.code]">
                      {{ errors['tags.' + index + '.title.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.href.label')"
                    :label-for="'tags.' + index + '.href.' + language.code"
                  >
                    <b-form-input
                      :id="'tags.' + index + '.href.' + language.code"
                      v-model="tag.href[language.code]"
                      :state="errors && errors['tags.' + index + '.href.' + language.code] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['tags.' + index + '.href.' + language.code]">
                      {{ errors['tags.' + index + '.href.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <!-- Remove Button -->
                <b-col
                  lg="2"
                  md="3"
                  class="mb-50"
                >
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    @click="removeTag(index)"
                  >
                    <feather-icon
                      icon="XIcon"
                      class="mr-25"
                    />
                    <span>{{ $t('general.delete') }}</span>
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </div>
          </b-tab>
        </b-tabs>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="addTag"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>{{ $t('general.add') }}</span>
        </b-button>

        <b-row
          v-if="errors && errors.tags"
        >
          <b-col cols="12">
            <b-form-invalid-feedback :state="false">
              {{ errors.tags[0] }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
        <b-row
          v-if="$ability.can('update', 'settings')"
          class="mt-2"
        >
          <b-col>
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="submitCatalog"
            >
              {{ $t('general.save') }}
            </b-button>
          </b-col>
        </b-row>
      </b-tab>

    </b-tabs>
  </component>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor, Quill } from 'vue-quill-editor'
import htmlEditButton from 'quill-html-edit-button'
import EditorMixin from '@/mixins/EditorMixin'

import {
  BCard,
  BRow,
  BCol,
  BForm,
  BButton,
  BOverlay,
  BFormGroup,
  BFormInvalidFeedback,
  BFormCheckbox,
  BFormInput,
  BTab,
  BTabs,
  BImg, BMediaBody, BMedia, BLink, BMediaAside, BFormTextarea,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import vSelect from 'vue-select'
import StatusMixin from '@/mixins/StatusMixin'
import Ripple from 'vue-ripple-directive'

import _ from 'lodash'
import InitFileManager from '@/components/InitFileManager'

Quill.register('modules/htmlEditButton', htmlEditButton)

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BTab,
    BTabs,
    BRow,
    BCol,
    BForm,
    BButton,
    BOverlay,
    BFormGroup,
    BFormInvalidFeedback,
    vSelect,
    BFormCheckbox,
    BFormInput,
    BImg,
    InitFileManager,
    BMediaBody,
    BMedia,
    BLink,
    BMediaAside,
    quillEditor,
    BFormTextarea,
  },
  mixins: [StatusMixin, EditorMixin],
  data() {
    return {
      languages: [],
      seo: {
        type: 'coming_soon',
        title: {},
        description: {},
        avatar: null,
        faq: [],
      },
      catalog: {
        type: 'coming_soon',
        top_extension_id: null,
        bottom_extension_id: null,
        title: {},
        description: {},
        meta_description: {},
        meta_h1: {},
        meta_keywords: {},
        meta_title: {},
        indexing_status: 'enabled',
        canonical: {},
        tags: [],
      },
      data: null,
      attributesOptions: [],
      attributeValuesOptions: [],
      extensionOptions: [],
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data

        _.each(this.languages, language => {
          this.seo.title[language.code] = null
          this.seo.description[language.code] = null
        })
      })

    await this.$http('/api/administration/catalog/seo', { params: { type: 'coming_soon' } }).then(response => {
      const { data } = response.data

      this.seo.avatar = data && data.avatar ? this.$options.filters.singleMedia(data.avatar) : null

      _.each(this.languages, language => {
        const translation = data ? _.find(data.translations, { locale: language.code }) : null

        this.seo.title[language.code] = translation ? translation.title : null
        this.seo.description[language.code] = translation ? translation.description : null
      })

      if (data && data.faq) {
        _.each(data.faq, (faq, index) => {
          this.seo.faq[index] = {
            id: faq.id,
            question: {},
            answer: {},
          }

          _.each(this.languages, language => {
            const translation = _.find(faq.translations, { locale: language.code })

            this.seo.faq[index].question[language.code] = translation ? translation.question : null
            this.seo.faq[index].answer[language.code] = translation ? translation.answer : null
          })
        })
      }
    })

    await this.$http('/api/administration/catalog', { params: { type: 'coming_soon' } }).then(response => {
      const { data } = response.data

      this.catalog.indexing_status = data.indexing_status

      _.each(this.languages, language => {
        const translation = data ? _.find(data.translations, { locale: language.code }) : null

        this.catalog.title[language.code] = translation ? translation.title : null
        this.catalog.description[language.code] = translation ? translation.description : null
        this.catalog.meta_description[language.code] = translation ? translation.meta_description : null
        this.catalog.meta_h1[language.code] = translation ? translation.meta_h1 : null
        this.catalog.meta_keywords[language.code] = translation ? translation.meta_keywords : null
        this.catalog.meta_title[language.code] = translation ? translation.meta_title : null
        this.catalog.canonical[language.code] = translation ? translation.canonical : null
      })

      if (data && !_.isNull(data.topExtension)) {
        this.catalog.top_extension_id = data.topExtension.id
      }

      if (data && !_.isNull(data.bottomExtension)) {
        this.catalog.bottom_extension_id = data.bottomExtension.id
      }

      if (data && !_.isNull(data.topExtension) && !_.find(this.extensionOptions, { value: data.topExtension.id })) {
        this.extensionOptions.push({
          value: data.topExtension.id,
          label: data.topExtension.title,
        })
      }

      if (data && !_.isNull(data.bottomExtension) && !_.find(this.extensionOptions, { value: data.bottomExtension.id })) {
        this.extensionOptions.push({
          value: data.bottomExtension.id,
          label: data.bottomExtension.title,
        })
      }

      if (data && !_.isNull(data.tags)) {
        _.each(data.tags, (brand, index) => {
          this.catalog.tags[index] = {
            id: brand.id,
            title: {},
            href: {},
          }

          _.each(this.languages, language => {
            const translation = _.find(brand.translations, { locale: language.code })

            this.catalog.tags[index].title[language.code] = translation ? translation.title : null
            this.catalog.tags[index].href[language.code] = translation ? translation.href : null
          })
        })
      }
    })

    await this.$http('/api/administration/catalog/filter', { params: { type: 'coming_soon' } }).then(response => {
      const { data } = response.data

      this.data = data
    })
  },
  created() {
    if (!Object.keys(this.attributesOptions).length) {
      this.$http.get('/api/attributes', { params: { status: 'enabled', per_page: 100, relations: 'values.translations' } })
        .then(response => {
          this.attributesOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')

          const vm = this

          _.each(response.data.data, item => {
            vm.attributeValuesOptions[`attribute_${item.id}`] = vm.$options.filters.transformForVSelect(item.values, 'id', 'title')
          })
        })
    }
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    addFaq() {
      const data = {
        question: {},
        answer: {},
      }

      _.each(this.languages, language => {
        data.question[language.code] = null
        data.answer[language.code] = null
      })

      this.seo.faq.push(data)
    },
    removeFaq(index) {
      this.seo.faq.splice(index, 1)
    },
    selectedAvatar(data) {
      this.seo.avatar = data
    },
    async searchExtension(query) {
      await this.$http.get('/api/administration/extensions', {
        params: {
          query,
        },
      })
        .then(response => {
          this.extensionOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
    async loadAttributeValue(id, index) {
      this.data[index].attribute_value_id = null

      if (this.attributeValuesOptions && _.has(this.attributeValuesOptions, `attribute_${id}`)) {
        return
      }

      const response = await this.$http.get(`/api/attributes/${id}/values`)

      this.$set(this.attributeValuesOptions, `attribute_${id}`, await this.$options.filters.transformForVSelect(response.data.data, 'id', 'title'))
    },
    addAttribute() {
      this.data.push({
        attribute_id: null,
        attribute_value_id: null,
        position: Object.keys(this.data).length + 1,
        can_indexing: false,
        hide_empty: false,
      })
      this.attributeValuesOptions.push([])
    },
    removeAttribute(index) {
      this.data.splice(index, 1)
      this.attributeValuesOptions.splice(index, 1)
    },
    addTag() {
      const data = {
        title: {},
        href: {},
      }

      _.each(this.languages, language => {
        data.title[language.code] = null
        data.href[language.code] = null
      })

      this.catalog.tags.push(data)
    },
    removeTag(index) {
      this.catalog.tags.splice(index, 1)
    },
    async submit() {
      await this.$http.post('/api/administration/catalog/filter/coming_soon', this.data)
        .then(async () => {
          this.$store.dispatch('validation/clearErrors')

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              text: this.$t('notifications.success.updated'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
    },
    async submitSeo() {
      await this.$http.post('/api/administration/catalog/seo', this.seo)
        .then(async () => {
          this.$store.dispatch('validation/clearErrors')

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              text: this.$t('notifications.success.updated'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
    },
    async submitCatalog() {
      await this.$http.post('/api/administration/catalog', this.catalog)
        .then(async () => {
          this.$store.dispatch('validation/clearErrors')

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              text: this.$t('notifications.success.updated'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
